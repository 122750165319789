import { gql, useQuery } from '@apollo/client'
import styled from '@emotion/styled'
import GalleryGrid from 'components/GalleryGrid'
import Layout from 'components/Layout'
import ModuleHeader from 'components/ModuleHeader'
import RedirectUnauthed from 'components/RedirectUnauthed'
import useAuth from 'hooks/useAuth'
import Link from 'next/link'
import { useState } from 'react'
import tw from 'twin.macro'

import { GALLERY_QUERY } from './gallery'

export const GALLERY_QUERY_RECENT = gql`
  query galleryQuery {
    queryKaraokePerformance(filter: { isUploaded: true }, order: { desc: createdAt }, first: 8) {
      id
      offset
      song {
        title
        artist
        videoUrl
      }
      thumbnail
      mp4
      webm
      likedByAggregate {
        count
      }
      likedBy {
        name
        id
      }
      user {
        avatarImage
        name
        avatarImageIsUploaded
      }
      createdAt
    }
  }
`

export default function Index() {
  const { currentUser, logout } = useAuth()
  const [top10, setTop10] = useState()

  useQuery(GALLERY_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => sortTop10(data),
  })
  const { loading, error, data } = useQuery(GALLERY_QUERY_RECENT)

  const responsive = {
    // supersuperLargeDesktop: {
    //   // the naming can be any, depends on you.
    //   breakpoint: { max: 4000, min: 1870 },
    //   items: 6,
    // },
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1600 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 1600, min: 1270 },
      items: 4,
    },
    horizontalTablet: {
      breakpoint: { max: 1270, min: 968 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 968, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }

  const sortTop10 = (data) => {
    if (data.queryKaraokePerformance.length > 0) {
      let result = {}
      let sortableData = data.queryKaraokePerformance
      let sortedData = sortableData.slice().sort((a, b) => b.likedByAggregate.count - a.likedByAggregate.count)
      let arr = []
      sortedData.slice(0, 5).map((item, i) => {
        arr.push(item)
      })
      result.queryKaraokePerformance = arr
      setTop10(result)
    }
  }

  return (
    <RedirectUnauthed to="/login">
      <Layout>
        <HeroImage src={'LiveStreamFrame.jpg'} />
        <Page>
          <ModuleHeader
            title={'Hall of Fame'}
            subtitle={'Karaoke, Hip Hop Freestyle, Dance'}
            text={'Be inspired and loop up your own song for the hall of fame.'}
            bgColor={'#4FA0D3'}
            color={'#000'}
            imgUrl={'https://picsum.photos/id/1/1920/1080'}
            lottie={'LottieTurnTable'}
          />
          <div tw="bg-zalando-turquese-300 w-full flex justify-center h-auto min-h-full py-16 px-8 flex flex-col ">
            <div tw="max-w-7xl m-auto w-full">
              <SectionTitle>Top 10</SectionTitle>
            </div>
            <div tw="flex justify-center">
              <GalleryGrid imageList={top10} numberOfImages={10} gridOptions={responsive} />
            </div>
          </div>
          <div tw="w-full max-w-7xl px-12 xl:px-0 mt-64">
            <SectionTitle>Most recent</SectionTitle>

            {loading ? <p>Loading...</p> : <GalleryGrid pagination filtered imageList={data} />}

            <LoadWrapper>
              <Link href="/gallery">
                <LoadMore>Load More</LoadMore>
              </Link>
            </LoadWrapper>
          </div>
        </Page>
      </Layout>
    </RedirectUnauthed>
  )
}

export function getStaticProps() {
  // Note that in this case we're returning the state directly, without creating
  // the store first (like in /pages/ssr.js), this approach can be better and easier
  return {
    props: {
      initialReduxState: {
        chatOpen: true,
        chatDisabled: false,
        currentColor: 'black',
        currentChatTab: 'global',
      },
    },
  }
}

const HeroImage = styled.img(tw`
  w-full
  `)

const Page = styled.div(tw`
  flex
  justify-center
  items-center
  flex-col
  w-full
  `)

const SectionTitle = styled.h1(tw`
  text-base
  font-bold
  tracking-widest

`)

const LoadWrapper = styled.div(tw`
  flex
  w-full
  justify-center
  mt-8
`)
const LoadMore = styled.div(tw`
  px-12
  py-4
  border-2
  border-black
  font-semibold
  hover:cursor-pointer
  hover:bg-black
  hover:text-white
  `)
